import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
    container,
    grid,
    sectionBox,
    radiusBox,
    form,
} from './client-sms-template-create.module.scss';
import { ISection } from '../../models/section.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import useTranslations from '../../hooks/use-translations';
import { getUrlParamValue } from '../../utils/get-url-param-value';
import { usePagePathname } from '../../hooks/use-page-pathname';

import Section from '../hoc/section';
import RadiusBox from '../hoc/radius-box';
import DashboardHeader from '../molecules/dashboard-header';
import ClientSMSTemplateForm from '../organisms/client-sms-template-form';

export interface IClientSMSTemplateCreateProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

interface IClientSMSTemplateCreateQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale'>>;
}

const ClientSMSTemplateCreate: React.FC<IClientSMSTemplateCreateProps> = ({
    className = '',
    section,
    TitleTag,
}) => {
    const templateId = getUrlParamValue('id');
    const { style, css, sectionId } = section;
    const t = useTranslations('ClientSMSTemplateCreate');
    const { allPage } = useStaticQuery<IClientSMSTemplateCreateQueryResult>(query);
    const cancelFallbackLink = usePagePathname(allPage);

    return (
        <Section
            className={`${sectionBox} ${className}`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={container}>
                <DashboardHeader TitleTag={TitleTag} hint={t.hint}>
                    {templateId ? t.title.edit : t.title.create}
                </DashboardHeader>
                <RadiusBox className={radiusBox}>
                    <ClientSMSTemplateForm
                        className={form}
                        templateId={templateId}
                        cancelFallbackLink={cancelFallbackLink}
                    />
                </RadiusBox>
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        allPage(filter: { type: { eq: "client-sms-templates" } }) {
            edges {
                node {
                    locale
                    pathname
                }
            }
        }
    }
`;

export default ClientSMSTemplateCreate;
