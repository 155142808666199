import React from 'react';
import { Formik, Form, FormikConfig } from 'formik';
import { navigate } from 'gatsby';

import { container, actions, loading, globalError } from './client-sms-template-form.module.scss';
import useTranslations from '../../hooks/use-translations';
import { useClientSMSTemplate } from '../../hooks/use-client-sms-template';
import {
    getClientSMSTemplateFields,
    getClientSMSTemplateInitialValues,
    getClientSMSTemplateValidationSchema,
    IClientSMSTemplateValues,
} from '../../formik/client-sms-template.form';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { useBackLink } from '../../hooks/use-back-link';
import { useLanguageConfig } from '../../hooks/use-language-config';

import FieldGenerator from '../molecules/field-generator';
import Button from '../atoms/button';
import BackLink from '../hoc/back-link';
import FetchStatusInfo from '../molecules/fetch-status-info';
import NoPermissionInfo from './no-permission-info';

interface IClientSMSTemplateFormProps {
    className?: string;
    templateId?: number | string | null;
    cancelFallbackLink?: string;
}

const ClientSMSTemplateForm: React.FC<IClientSMSTemplateFormProps> = ({
    className = '',
    templateId,
    cancelFallbackLink,
}) => {
    const t = useTranslations('ClientSMSTemplateForm');
    const template = useClientSMSTemplate({ templateId, queries: ['single'] });
    const errors = templateId ? template.update.errors : template.create.errors;
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(errors);
    const prevPathname = useBackLink(cancelFallbackLink);
    const languageConfig = useLanguageConfig();

    const isLoading = template.update.isLoading || template.create.isLoading;

    const handleSubmit: FormikConfig<IClientSMSTemplateValues>['onSubmit'] = async (values) => {
        try {
            if (templateId) {
                await template.update.fetch({ templateId: templateId, data: values }).unwrap();
            } else {
                await template.create.fetch(values).unwrap();
            }
            await navigate(prevPathname, { replace: true });
        } catch {}
    };

    if (templateId && template.single.isUnauthorized) {
        return <NoPermissionInfo reason="role" />;
    }

    if (templateId && (template.single.isLoading || template.single.isError)) {
        return (
            <FetchStatusInfo
                isLoading={template.single.isLoading}
                isError={template.single.isError}
                error={t.fetchError}
                backLinkProps={{ fallback: prevPathname }}
            />
        );
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={getClientSMSTemplateInitialValues(template.single.data)}
            validationSchema={getClientSMSTemplateValidationSchema(t)}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {() => (
                <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                    {getClientSMSTemplateFields(t, languageConfig.options).map((field) => {
                        return (
                            <FieldGenerator
                                key={`client-sms-template-field-${field.name}-${field.type}`}
                                field={field}
                            />
                        );
                    })}
                    {globalErrorMessage && <p className={globalError}>{globalErrorMessage}</p>}
                    <div className={actions}>
                        <BackLink fallback={cancelFallbackLink}>{t.button.cancel}</BackLink>
                        <Button type="submit" isLoading={isLoading}>
                            {t.button.submit}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ClientSMSTemplateForm;
